import { Component, OnInit, Renderer2 } from '@angular/core';
import { MessageService } from '../service/message.service'
import { CurrentuserService } from '../service/currentuser.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']

})
export class HeaderComponent implements OnInit {
  public listBusinessUnit: any;
  public selectedBu: any;
  tSchedulingSetupAccess: boolean = false;

  constructor(private messageService: MessageService,
    private _currentUserService: CurrentuserService,
    private _renderer: Renderer2
  ) {
    this.listBusinessUnit = [];
  }

  ngOnInit() {
    this.messageService.getSelectedBusinessUniIdOnDashboard().subscribe(iSelectedBusinessUnitId => {
      this.selectedBu = iSelectedBusinessUnitId;
    });
    this._currentUserService.getUserSession().subscribe((res: any) => {
      this.listBusinessUnit = res.listBusinessUnit;
      this.tSchedulingSetupAccess = res.tSchedulingSetupAccess;

      if (res.listBusinessUnit.length > 0)
        this.selectedBu = this.listBusinessUnit[0].iEMLocationId;

      if (res.strCustomerGroup == undefined || res.strCustomerGroup == null || res.strCustomerGroup == "")
        res.strCustomerGroup = "Analytix";

      this._renderer.addClass(document.body, res.strCustomerGroup);
    });
  }
  onLocationChanged(data): void {
    if (data != undefined)
      this.messageService.sendMessage(data.iEMLocationId, this.listBusinessUnit);
  }
}


