import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as env from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  constructor(private http: HttpClient) { }

  getEmployeeJobs(iEmployeeId: number) {
    return this.http.get(env.configUrl + "Employee/jobs?iEmployeeId=" + iEmployeeId);
  }

  getEmployeeByBusinessUnit(iBusinessUnitId: number) {
    return this.http.get(env.configUrl + "Employee/businessunit?iBusinessUnitId=" + iBusinessUnitId);
  }


}
