import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { DashboardCopyComponent } from './dashboard-copy/dashboard-copy.component';
import { SessionComponent } from './session/session.component';
import { FormulaSetupComponent } from './formula-setup/formula-setup.component';

const routes: Routes = [
  { path: '', component: DashboardComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'myprofile', component: MyProfileComponent },
  { path: 'dashboard-copy', component: DashboardCopyComponent },
  { path: 'session', component: SessionComponent },
  { path: 'formula-setup', component: FormulaSetupComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash:true}) ],
  exports: [RouterModule] 
})
export class AppRoutingModule { }
