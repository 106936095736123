// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

//Stagging
export const configUrl = "https://api.pay.qa.aixsol.com/api/";
export const schedulingUrl = "/";

export const salesApiUrl = "https://api.sales.qa.aixsol.com/api/";
export const weatherApiUrl = "https://api.weather.dev.aixsol.com/";
export const forcastApiUrl = "https://odp.qa.aixsol.com/services/DunkinDonutService.asmx/";

//Prod
//export const configUrl = "https://insightpay.analytix.com/apicore/api/";
//export const schedulingUrl = "/";

//export const salesApiUrl = "https://api.analytix.com/InsightSales/api/";
//export const weatherApiUrl = "https://api.analytix.com/weatherapi/api/";
//export const forcastApiUrl = "https://dunkin.insight360.analytix.com/services/DunkinDonutService.asmx/";
