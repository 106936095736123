import { Component, OnInit, Input } from '@angular/core';
import { ParamEmployeeSchedule } from '../Models/ParamEmployeeSchedule';
import * as moment from 'moment';

@Component({
  selector: 'app-day-view',
  templateUrl: './day-view.component.html',
  styleUrls: ['./day-view.component.scss']
})
export class DayViewComponent implements OnInit {
  @Input() paramEmployeeSchedule: ParamEmployeeSchedule;
  @Input() listScheduleSubject: any;
  listDayTime: Array<any> = [];
  listDayTimeCount: Array<Number> = [];
  workDayStart: any = "2019-01-01 08:00 AM";
  listSchedule:any;

  constructor() {
  }

  ngOnInit() {
    this.listScheduleSubject.subscribe(event => {
      debugger;
      this.listSchedule=event;
    });
  }

  ngOnDestroy() {   
    this.listScheduleSubject.unsubscribe();
  }

  setDayView() {
    this.listDayTimeCount = [];
    this.listDayTime = [];
    if (this.listSchedule.dtWorkDayStart != null && this.listSchedule.dtWorkDayStart != "")
      this.workDayStart = this.listSchedule.dtWorkDayStart;

    for (var i = 1; i <= 24; i++) {
      this.listDayTimeCount.push(i);
      var date = moment(new Date(this.workDayStart), 'h a').add(i - 1, 'hours').format('h a');
      this.listDayTime.push(date);
    }
  }

  arrayOne(n: number, remove: number) {
    return Array(n - remove);
  }

}
