import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as env from '../../environments/environment';
import { ParamEmployeeSchedule, ParamEmployeeScheduleSave } from '../Models/ParamEmployeeSchedule';
import { ParamsCopySchedule } from '../Models/params-copy-schedule';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class EmployeeScheduleService {

  constructor(private http: HttpClient) { }

  getEmployeeScheduleDetails(paramEmployeeSchedule: ParamEmployeeSchedule) {

    return this.http.post(env.configUrl + "EmployeeSchedule/getEmployeeScheduleDetails", paramEmployeeSchedule);
  }

  getLastYearTotal(paramEmployeeSchedule: ParamEmployeeSchedule) {
    const headers = new HttpHeaders().set('X-Skip-Interceptor', '');
    return this.http.post(env.configUrl + "EmployeeSchedule/getLastYearLabor", paramEmployeeSchedule, { headers });
  }

  getActualSales(paramEmployeeSchedule: ParamEmployeeSchedule) {
    const headers = new HttpHeaders().set('X-Skip-Interceptor', '');
    return this.http.get(env.salesApiUrl + "Sales?iBusinessUnitId=" + paramEmployeeSchedule.iBusinessUnitId
      + "&dateTimeStart=" + paramEmployeeSchedule.dateTimeFrom + "&dateTimeEnd=" + paramEmployeeSchedule.dateTimeTo, { headers });
  }

  getBudget(paramFilter: any) {
    const headers = new HttpHeaders().set('X-Skip-Interceptor', '');
    if (paramFilter.tFromIndustry)
      return this.http.post(env.salesApiUrl + "Budget/industry", paramFilter, { headers });
    else
      return this.http.post(env.salesApiUrl + "Budget", paramFilter, { headers });
  }

  public getJSON() {
    return this.http.get("./assets/test.json");
  }

  saveEmployeeScheduleDetails(paramEmployeeScheduleSave: ParamEmployeeScheduleSave) {
    return this.http.post(env.configUrl + "EmployeeSchedule", paramEmployeeScheduleSave);
  }

  copyFromEmployeeSchedule(paramsCopySchedule: ParamsCopySchedule) {
    return this.http.post(env.configUrl + "EmployeeSchedule/copyschedule", paramsCopySchedule);
  }

  deleteEmployeeScheduleDetails(deleteEmployeeSchedule: any) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: deleteEmployeeSchedule
    };

    return this.http.delete(env.configUrl + "EmployeeSchedule/deleteSchedule", options);
  }

  getTerminatedEmployeeDetails(paramsCopySchedule: ParamsCopySchedule) {
    return this.http.post(env.configUrl + "EmployeeSchedule/getTerminatedEmployeeDetails", paramsCopySchedule);
  }
  exportToPDF(listschedule: any, businessUnitName: string, customerGroup: string, orientation: any) {
    var postObject = { listSchedules: listschedule, strBusinessUnitName: businessUnitName, strCustomerGroup: customerGroup ,orientation : orientation}
    return this.http.post(env.schedulingUrl + "Export/GeneratePdf", postObject);
  }

  getFile(path: string): Observable<any> {
    //  let options = new HttpHeaders({ responseType: ResponseContentType.Blob });
    return this.http.get(env.schedulingUrl + "Export/download?filename=" + path, { responseType: 'blob' });
  }

  GetLaborBudget(paramLaborBudget) {
    const headers = new HttpHeaders().set('X-Skip-Interceptor', '');
    if (paramLaborBudget.tFromIndustry)
      return this.http.post(env.schedulingUrl + "Sales/ListGetLaborBudget", { dateTimeStart: paramLaborBudget.dateTimeStart, dateTimeEnd: paramLaborBudget.dateTimeEnd, iBusinessUnitId: paramLaborBudget.listBusinessUnitIds[0] }, { headers });
    else
      return this.http.post(env.configUrl + "Budget", paramLaborBudget, { headers });
  }

  getWeather(weatherParams) {
  return this.http.get(env.weatherApiUrl + "getPeriodicWeather?zipCode=" + weatherParams.strZipCode + "&FromDate=" + weatherParams.dateTimeStart + "&ToDate=" + weatherParams.dateTimeEnd);
  }

  getWeatherHourly(weatherParams) {
    return this.http.get(env.weatherApiUrl + "getHourlyWeather?zipCode=" + weatherParams.strZipCode + "&date=" + weatherParams.dateTimeStart);
  }

  getForcast(forcastParams) {
    //return this.http.get(env.forcastApiUrl + "ListGetSalesForecast?iBusinessUnitId=" + forcastParams.iBusinessUnitId + "&dateTimeStart=" + forcastParams.dateTimeStart + "&dateTimeEnd=" + forcastParams.dateTimeEnd);
    if (forcastParams.tFromIndustry)
      return this.http.post(env.schedulingUrl + "Sales/ListGetSalesForecast", forcastParams);
    else
      return this.http.get(env.salesApiUrl + "Forecast?iBusinessUnitId=" + forcastParams.iBusinessUnitId + "&dateTimeStart=" + forcastParams.dateTimeStart + "&dateTimeEnd=" + forcastParams.dateTimeEnd);
  }

  getLaborForcast(forcastParams) {

    return this.http.post(env.schedulingUrl + "Sales/ListGetLaborForecast", forcastParams);

  }

  updatePublishScheduleStatus(payload) {
    return this.http.post(env.configUrl + "EmployeeSchedule/publishSchedule", payload);
  }

  getDiscountSales(paramEmployeeSchedule: ParamEmployeeSchedule) {
    let req = {
      listBusinessUnitIds: [paramEmployeeSchedule.iBusinessUnitId],
      dateTimeStart: paramEmployeeSchedule.dateTimeFrom,
      dateTimeEnd: paramEmployeeSchedule.dateTimeTo
    };
    //const headers = new HttpHeaders().set('X-Skip-Interceptor', '');
    return this.http.post(env.salesApiUrl + "Discount/salesdiscountsforsummarysourcing", req);
  }
}
