import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DateService {
  public EMStartDate: Date;
  strDateFormat: String = "MM/DD/YYYY";
  checkDateDays: Number;
  iPayrollPeriod: Number;
  payrollPeriod: Number;

  constructor() {


  }
  setEMStartDate = function (selectedDate) {
    this.EMStartDate = selectedDate;
  }
  getPayrollDates = function (selectedDate) {
    var payrollPeriod = 7;
    var iPayrollPeriod = 7;
    //var EMStartDate = "04/14/2019";
    var checkDateDays = 5;

    var payrollPeriodStartDate = moment(selectedDate, this.strDateFormat);

    var payrollPeriodEndDate;
    if (moment(this.EMStartDate).isAfter(payrollPeriodStartDate)) {

      var dayDiff = moment(this.EMStartDate).diff(payrollPeriodStartDate, 'days');
      var iDayMod = dayDiff % iPayrollPeriod;
      if (iDayMod != 0)
        payrollPeriodStartDate = moment(moment(payrollPeriodStartDate).add(-((iPayrollPeriod - iDayMod)), 'days'), this.strDateFormat);
      var date = payrollPeriodStartDate;
      payrollPeriodStartDate = moment(date, this.strDateFormat);
      payrollPeriodEndDate = moment(date).add(iPayrollPeriod - 1, 'days').format(this.strDateFormat);
    }
    else {
      var dayDiff = payrollPeriodStartDate.diff(this.EMStartDate, 'days');
      var iDayMod = dayDiff % iPayrollPeriod;
      var date = payrollPeriodStartDate;
      payrollPeriodStartDate = moment(moment(date).add(-iDayMod, 'days'), this.strDateFormat);
      payrollPeriodEndDate = moment(payrollPeriodStartDate, this.strDateFormat).add(iPayrollPeriod - 1, 'days').format(this.strDateFormat);
    }
    if (payrollPeriod == 1)
      payrollPeriodStartDate = selectedDate;
    var payrollCheckDate = moment(payrollPeriodEndDate, this.strDateFormat).add(checkDateDays, 'days').format(this.strDateFormat);

    return {
      payrollPeriodStartDate: payrollPeriodStartDate.format(this.strDateFormat),
      payrollPeriodEndDate: payrollPeriodEndDate,
      payrollCheckDate: payrollCheckDate,
      payrollPeriod: payrollPeriod
    }
  };

  closest(number, arr, tIncrease) {   
    let next; 
    if(tIncrease)  
      next = Math.max.apply(Math, arr);
    else  
      next = Math.min.apply(Math, arr);

    for (var val = 0; val < arr.length; val++) {
      if(tIncrease)  {
         if (arr[val] >= number && arr[val] < next) 
            next = arr[val];
      }
      else{
        if (arr[val] <= number && arr[val] > next) 
          next = arr[val];  
      } 
    }   

   let curr = next;
    curr = (curr >= 10) ? curr : '0' + curr; //If result is 5, to display '05' instead of '5'
    return curr;
  }

  nearest(value, min, max, steps){
    var zerone = Math.round((value-min)*steps/(max-min))/steps; // bring to 0-1 range
    return zerone*(max-min) + min;
  }

}
