import { Component, OnInit, Input, Output, OnDestroy, EventEmitter } from '@angular/core';
import { EmployeeScheduleService } from '../service/employee-schedule.service'

import { ParamEmployeeSchedule, ParamEmployeeScheduleSave } from '../Models/ParamEmployeeSchedule';
import { DateService } from '../service/date.service';
import { Subscription, Subject } from 'rxjs';
import { EmployeeScheduleModel } from '../Models/EmployeeScheduleModel';
import * as moment from 'moment';

import { BusinessUnitModel } from '../Models/BusinessUnitModel';
declare var $: any;
import * as _ from 'lodash';



@Component({
  selector: 'app-mobile-view',
  templateUrl: './mobile-view.component.html',
  styleUrls: ['./mobile-view.component.scss']
})
export class MobileViewComponent implements OnInit { 
@Input() shiftStart : any;
@Input() listSchedule: any;
@Input() iBusinessUnitId: number;
@Input() listUserDepartmentIds : Array<number>;
@Input() listBusinessUnit: Array<BusinessUnitModel>;
@Output() openAddShiftPopup: EventEmitter<boolean> = new EventEmitter<boolean>();
@Input() tIsPublished : boolean;
@Input() tCanEditPublishedSchedule : boolean;

  paramEmployeeSchedule = new ParamEmployeeSchedule();
  currentUserSession: any; 
  selectedDate: Date;
  payPeriod: any;
  _subscription = new Subscription();
  subscription: Subscription;
  employeeScheduleModel = new EmployeeScheduleModel();
  strSelectedTime: string;
  
  selectedEmployment: any;  
  listEmployeeJobs: Array<any>; 
  tShowBusinessUnit: boolean = true;  
  selectedTimeFrom: any;
  selectedTimeTo: any;  
  strDateFormatDB: string = "yyyy-MM-dd";
  strTimeFormatDB: string = "HH:mm:ss";
  strDateFormat: String = "MM/DD/YYYY";
  paramEmployeeScheduleSave = new ParamEmployeeScheduleSave();
  dblHRLimit: number = 0;
  tShowHRLimitWarning: boolean = false;
  shiftTotalHours: number = 0;
  strTimeDisplay: string = "hh:mm a";
 
  constructor(
    private _empScheduleService: EmployeeScheduleService,
    private _DateService: DateService) {

    this.paramEmployeeSchedule.tScheduleByDay = false;

  }
  ngOnInit() {
    this.Login();
  }
  Login() {    
    this.setdefaultDate();    
  }

  updatePayrollWeekEndDay() {
    this.payPeriod = this._DateService.getPayrollDates(this.selectedDate);
    this.payPeriod = {
      payrollPeriodEndDate: this.payPeriod.payrollPeriodStartDate,
      payrollPeriodStartDate: this.payPeriod.payrollPeriodStartDate
    };
    this.selectedDate = this.payPeriod.payrollPeriodStartDate;
    this.getEmployeScheduleDetails();
  }

  setdefaultDate() {
    this.payPeriod = this._DateService.getPayrollDates(new Date());
    this.selectedDate = this.payPeriod.payrollPeriodStartDate;
  }

  selectPreviousPayrollPeriod = function () {
    this.payPeriod = {
      payrollPeriodEndDate: moment(this.selectedDate, 'MM/DD/YYYY').add(-1, 'days').format('MM/DD/YYYY'),
      payrollPeriodStartDate: moment(this.selectedDate, 'MM/DD/YYYY').add(-1, 'days').format('MM/DD/YYYY')
    };
    this.selectedDate = this.payPeriod.payrollPeriodStartDate;
    this.getEmployeScheduleDetails();
  };

  selectNextPayrollPeriod = function () {
    this.payPeriod = {
      payrollPeriodEndDate: moment(this.selectedDate, 'MM/DD/YYYY').add(1, 'days').format('MM/DD/YYYY'),
      payrollPeriodStartDate: moment(this.selectedDate, 'MM/DD/YYYY').add(1, 'days').format('MM/DD/YYYY')
    };
    this.selectedDate = this.payPeriod.payrollPeriodStartDate;
    this.getEmployeScheduleDetails();
  };

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  getEmployeScheduleDetails() {
    if (this.payPeriod != undefined) {
      this.paramEmployeeSchedule.dateTimeTo = this.payPeriod.payrollPeriodEndDate;
      this.paramEmployeeSchedule.iBusinessUnitId = this.iBusinessUnitId;
      this.paramEmployeeSchedule.dateTimeFrom = this.payPeriod.payrollPeriodStartDate;
      this.paramEmployeeSchedule.dateTimeTo = this.payPeriod.payrollPeriodStartDate;
      this._subscription.add(this._empScheduleService.getEmployeeScheduleDetails(this.paramEmployeeSchedule)
        .subscribe((res: any) => {
          this.listSchedule = res.data;
        })
      );
    }
  }
  public openAddShift(): void {
     this.openAddShiftPopup.next();
  }
}
